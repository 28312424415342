.button-nav1 {
  background-color: black;
  color: white;
  border: 1px solid white;
  margin-right: 1rem;
  padding: 5px 20px;
  border-radius: 10px;
}
.button-nav2 {
  background-color: black;
  color: white;
  border: 1px solid white;

  padding: 5px 20px;
  border-radius: 10px;
}
@media screen and (max-width: 1024px) {
  .navbar-toggler {
    display: none;
  }
  .navbar-collapse {
    display: none;
  }
  nav.navbar-light.navbar.navbar-expand-lg.bg-transparent {
    display: none !important;
  }
  nav.navbar-light.navbar.navbar-expand-lg.bg-transparent.adminnav {
    display: flex !important;
  }
}
/* @media screen and (min-width: 1024px) {
  .icon-fa {
    display: block !important;
  }
} */
.offcanvas-button {
  margin: 1rem auto;
}
.offcanvas-button2 {
  width: 90%;
  padding: 5px 20px;
  /* margin: 1rem; */
  display: block;
  color: black;
  border: 0.5px solid black;
  border-radius: 5px;
  background-color: white;
  margin: 0 auto;
}
div#offcanvasExample {
  background-color: white;
}
a.btn.btn-primary.icon-fa {
  box-shadow: none;
  font-size: 20px;
  background-color: transparent !important;
  border-color: white;
  padding: 5px 15px !important;
  color: rgb(0, 100, 240) !important;
}
.button.btn-close.text-reset {
  background-color: white !important;
}
#button-reset1 {
  color: white !important;
  background-color: white !important;
}
/* .container-fluid{
        --bs-gutter-x: 0rem !important;
} */
.offcanvas-button1 {
  width: 90%;
  background-color: transparent !important;
  margin: 0 auto !important;
  border-color: white !important;
  display: block !important;
}
.offcanvas {
  visibility: visible !important;
}
.under {
  display: flex !important;
  list-style-type: none !important;
}
.under li {
  margin-right: 1rem;
}
#buyyy {
  color: #fff !important;
}
a {
  color: #6d5f5f !important ;
}
#offcanvas-button5 {
  width: 90% !important;
  background-color: #fefefe !important;
  color: #6d5f5f !important ;
  border: 1px solid black !important;
  margin-bottom: 0px;
  margin-top: 0px !important;
}
#dropdownMenuButton2 {
  width: 90% !important;
  background-color: #fefefe !important;
  color: #6d5f5f !important;
  border: 1px solid black !important;
  display: block;
  margin: 0 auto;
  padding: 0.5rem;
  border-radius: 5px;
}
.login-button-admin {
  margin-left: 0px !important;
}
ul.dropdown-menu.disconnect1 {
  /* background-color: #0064f0; */
  color: #f144ec;
  text-align: center;
  font-weight: 500;
  width: 90%;
}
/* @media screen and (min-width: 1023px) {
  #button-reset45 {
    display: none !important;
  }
} */
@media screen and (max-width: 1025px) {
  div#navbarSupportedContent {
    display: none !important;
  }
}
/* @media screen and (max-width: 1025px) {
  a.btn.btn-primary.icon-fa {
    display: visible !important;
  }
} */
@media screen and (min-width: 1025px) {
  a.btn.btn-primary.icon-fa {
    display: none !important;
  }
}

@media screen and (max-width: 1115px) {
  a.nav-link {
    font-size: 13px;
  }
}
