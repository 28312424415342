.video{
    width: 85%;
    height: 25rem;
    display: block;
    margin: 2rem auto 1rem !important;
}
.text23{
    margin: 1rem auto;
    display: block;
    width: 26rem;
    border-radius: 50px;
    height: 4rem !important;
    color: black;
    font-size: 20px;
    padding: 0px 20px;
}
input,
input::placeholder {
    font-size: 13px;
    color: #9a9a9a;
    margin: 0;
}

.waring{
    text-align: center !important;
    color:  red;
    padding-right: 0px;
    margin-top: 0px;
}

.button1{
    display: block;
    margin: 1rem auto;
    border: none;
    background-color: #a6bcdc;
    color: white;
    color: #0064F0;
    width: 26rem;
    font-weight: 800;
    border-radius: 10px;
    height: 3rem;
}
.button2{
    display: block;
    margin: 1rem auto;
    border: none;
    background-color: #0064F0;
    color: white;
    /* color: #0064F0; */
    width: 26rem;
    font-weight: 800;
    border-radius: 10px;
    height: 3rem;
    margin-bottom: 4rem;
}

.table{
    width: 70% !important;
    margin:2rem auto;

  
}

@media screen and (max-width:750px) {
    .Table{
display: none;
    }
    .video{
        margin-left: 46px !important;
    }
}
@media screen and (min-width:750px) {
    .Table-mobile{
display: none;

    }
    .table{
        height: 39rem;
        float: right;
    }
}


.Table-mobile{
    width: 85% !important;
    height: 19rem;
    margin-top: 0 !important;
    width: 26rem !important;
    border:1px solid black;
}
.minted{
    font-weight: 900 !important;
    font-size: 24px !important;
}
.table-minted{
    border: 1px solid black;
}


@media screen and (max-width:500px) {
    .text23, .button1, .button2{
        width: 85%;
    }
    .Table-mobile{
        width:85% !important;
    }
  
    input,
input::placeholder {
    font-size: 11px;
    color: #9a9a9a;
}
}