input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.modal-title.h4 {
  display: block;
  margin-left: auto;
}
button.connectButton {
  width: 214px;
  padding: 10px 10px;
  border: none;
  font-weight: 500;
  background-color: #0064f0 !important;
  color: #fff;
  border-radius: 10px;
}
img.wallet-img {
  max-width: 120px;
  cursor: pointer;
}
img.wallet-img2 {
  max-width: 70px;
  cursor: pointer;
}
button.connectButton {
  width: 176px;
  padding: 10px 10px;
  border: none;
  font-weight: 500;
  background-color: #0064f0;
  color: #fff;
  border-radius: 10px;
}
button.connectButton2 {
  width: 300px;
  padding: 10px 10px;
  border: none;
  font-weight: 800;
  background-color: #0064f0 !important;
  color: #fff;
  border-radius: 10px;
}
.MuiContainer-root.MuiContainer-maxWidthXl.updatingprice.css-19r6kue-MuiContainer-root {
  margin-top: 40px;
  margin-bottom: 40px;
}
a#adminlink2 {
  display: block;
  margin-left: auto;
  margin-right: 20px;
  margin-top: 10px;
}
.card.my-5.mx-3 {
  margin-top: 5.3rem !important;
}
.card {
  min-height: 6rem;
}
.buttonspacing.MuiBox-root.css-0 {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.spacing.MuiBox-root.css-0 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
button.withdrawlbutton {
  width: 150px;
  padding: 10px 10px;
  margin-left: -1px;
  border: none;
  /* display: block;
  margin: auto; */
  font-weight: 800;
  background-color: #0064f0 !important;
  color: #fff;
  border-radius: 7px;
}
.row.withdraw {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: center;
}
div#active {
  background-color: #ce1a1a;
  /* background-color: #55c0b2; */
  text-align: center;
  margin: 1rem auto;
  border-radius: 10px;
  color: white;
}
div.pause {
  background-color: #55c0b2;
  text-align: center;
  margin: 1rem auto;
  border-radius: 10px;
  color: white;
}
button#dropdownMenuButton {
  padding: 11px 14px;
  border: 1px solid;
  font-weight: 500;
  background-color: #0064f0;
  border-radius: 5px;
  margin-top: 8px;
  border-radius: 7px;
  color: #fff;
  height: 3rem;
  width: 13rem;
}
p.MuiTypography-root.MuiTypography-body1.swap-button.update-status.css-lhbuvf-MuiTypography-root {
  margin-top: 5.5rem;
}
.btn-group,
.btn-group-vertical {
  box-shadow: none !important;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

ul.dropdown-menu.disconnect {
  background-color: #0064f0;
  /* color:  #f144ec; */
  text-align: center;
  font-weight: 500;
  /* width: 90%; */
  color: white;
  width: 100%;
}
h5.card-title {
  font-size: 17px;
  margin-top: 15px;
}
.network {
  cursor: pointer;
  padding: 5px 0px;
}
a.active {
  color: #000 !important;
  font-weight: 500;
}
a#link {
  /* margin-left: 20px; */
  color: black;
}
.gcexfooter.MuiBox-root.css-0 {
  background-color: #f6f6f6;
  margin-top: 10rem;
  margin-bottom: 0px !important;
}
a.socialarea {
  margin-left: 10px;
  margin-top: 20px;
  cursor: pointer;
}
input.rescuerinput {
  display: block;
  margin: auto;
}
.max-button {
  position: absolute;
  right: 10px;
  top: 62px;
  width: 70px;
  text-align: center;
  font-size: 10px;
  cursor: pointer;
  padding: 10px 10px;
  background: #0064f0;
  color: #fff;
}

p.MuiTypography-root.MuiTypography-body1.MuiTypography-paragraph.text-center.css-z2eky3-MuiTypography-root {
  padding-bottom: 20px;
}
i.fas.fa-arrows-alt-v.swap-icon.swap-icon {
  display: block;
  margin: 30px auto -10px;
  font-size: 30px;
  border: 1px solid;
  border-radius: 50%;
  padding: 10px;
  width: 50px;
  height: 50px;
  text-align: center;
  cursor: pointer;
  transition: 0.5s;
}
i.fas.fa-arrows-alt-v.swap-icon.swap-icon:hover {
  background-color: #0064f0;
  border: 1px solid #0064f0;
  color: #fff;
}

.title-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.swap {
  margin-top: 20px;
}
.swap-button {
  display: block;
  text-align: center;
  padding: 10px 10px;
  cursor: pointer;
  border: 1px solid;
  margin: auto;
  background-color: #0064f0;
  border: 1px solid #0064f0;
  color: #fff;
  width: 100%;
}
.footer-area {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 10px;
}

/* STAKING */

table.table.align-middle {
  border: 1px solid;
  margin-top: 60px;
  margin-bottom: 30px;
}
span.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiDataGrid-checkboxInput.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  display: none;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  border-bottom: 1px solid;
  background-color: transparent !important;
  border-radius: 0.25rem;
}

.css-1ex1afd-MuiTableCell-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba (224, 224, 224, 1);
  text-align: center !important;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
}
.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
  min-height: 52px;
  padding-right: 2px;
  display: none !important;
}
.css-41abqd-MuiTableContainer-root {
  width: 100%;
  overflow-x: auto;
  max-height: 440px;
  margin-top: 50px !important;
  margin-bottom: 50px;
}
nav.navbar-light.navbar.navbar-expand-lg.bg-transparent.adminnav {
  margin-top: 50px;
  margin-bottom: 30px;
  box-shadow: none;
  border-bottom: 1px solid;
  padding-bottom: 20px;
}
.css-41abqd-MuiTableContainer-root {
  width: 100%;
  overflow-x: auto;
  max-height: 440px;
  margin-top: 50px !important;
  margin-bottom: 50px;
  border: 1px solid;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.createbutton.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  background-color: #0064f0 !important;
  color: #fff !important;
  display: block !important;
  margin: 30px auto !important;
  font-size: 14px !important;
  width: 180px !important;
  padding: 10px 10px !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #f144ec !important;
}
.css-1aquho2-MuiTabs-indicator {
  position: absolute;
  height: 0px !important;
  bottom: 0;
  width: 100%;
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: none !important;
}
.cardcontent.MuiBox-root.css-0 {
  text-align: center;
  padding: 60px 0px;
}
.cardcontent2.MuiBox-root.css-0 {
  text-align: center;
  padding: 25px 0px;
}
input.stakedAmount {
  padding: 20px 20px;
  margin-top: -2rem;
  width: 100%;
}
.stakedsubmission {
  display: flex;
  align-items: baseline;
}
.d-grid {
  width: 80%;
  margin-left: 30px;
}
h4.ss-heading {
  color: #333333;
  font-family: josefin-sans, sans-serif;
  font-weight: 700;
  font-size: 18px;
  font-style: normal;
  margin: 15px 20px;
}
h4.srpayBalance {
  font-weight: 500;
}
h4.srpayBalance {
  font-family: josefin-sans, sans-serif;
}
button.stake-btton {
  background-color: #0064f0;
  border: none;
  color: #fff;
  font-family: josefin-sans, sans-serif;
  font-weight: 400;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
}
h3.stakingSummary {
  color: #333;
  font-size: 22px;
  font-weight: 700;
  padding: 40px 0 10px;
}
h3.stakingSummary,
h4.srpayBalance {
  font-family: josefin-sans, sans-serif;
}
.summary-content {
  align-items: center;
  display: flex;
}
.MuiPaper-root.MuiPaper-outlined.MuiPaper-rounded.MuiCard-root.css-1v5z4dq-MuiPaper-root-MuiCard-root {
  background: rgb(255, 255, 255);
  box-shadow: rgb(51 51 51 / 15%) 0px 4px 25px;
  cursor: pointer;
}
.MuiPaper-root.MuiPaper-outlined.MuiPaper-rounded.MuiCard-root.active.css-1v5z4dq-MuiPaper-root-MuiCard-root {
  border: 1px solid #f300f9;
  background: rgb(255, 255, 255);
  box-shadow: rgb(51 51 51 / 15%) 0px 4px 25px;
}
.ssc3,
.ssc4 {
  font-family: josefin-sans, sans-serif;
}
p.sc {
  margin-left: 7px;
}
.ssc3 {
  padding-left: 82px;
}
p.sc {
  margin-left: 7px;
}
.ssc4 {
  padding-left: 88px;
}
.ssc5 {
  padding-left: 122px;
}
.ssc {
  padding-left: 42px;
}
.ssc2 {
  padding-left: 32px;
}
.m-auto {
  margin: 0px !important;
}

a#link2 {
  margin: 0px 30px;
}
a#link3 {
  margin: 0px 20px;
}
.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1.swapcards.css-a5rdam-MuiGrid-root {
  margin-top: -45px;
}
a#adminlink {
  border: none;
  background-color: #0064f0;
  color: #fff !important;
  margin-left: auto;
  font-family: josefin-sans, sans-serif;
  font-weight: 400;
  padding: 10px;
  display: block;
  width: 20rem;
  height: 3rem;
  text-align: center;
  border-radius: 10px;
  font-weight: 600;
}
.css-2ulfj5-MuiTypography-root {
  border: 1px solid rgb(0, 100, 240) !important;
  width: 200px !important;
  background: rgb(0, 100, 240) !important;
  color: rgb(255, 255, 255) !important;
  text-align: center !important;
  margin: 20px 10px !important;
  padding: 7px 10px !important;
}

@media screen and (max-width: 500px) {
  .stakedsubmission {
    display: grid;
  }
  h4.ss-heading {
    display: block;
    margin: 1rem auto;
  }
  .d-grid {
    display: block;
    margin: 1rem auto;
  }
  .css-19kzrtu {
    padding: 2rem 0px !important;
  }
  button#dropdown-basic {
    display: block;
    margin: 10px auto;
  }
}
@media screen and (max-width: 480px) {
  button#dropdown-basic {
    display: block;
    margin: 10px auto;
  }
}

.reff-id {
  box-shadow: rgba(24, 23, 23, 0.15) 0px 4px 25px;
  background-color: rgb(229, 229, 229);
  padding: 1rem;
  color: rgb(31, 31, 31);
  border-radius: 10px;
}

.levels {
  text-align: center;
  padding: 1rem;
  font-size: 17px;
  width: fit-content;
  background-color: #0064f0;
  font-weight: 500;
  border-radius: 10px;
  margin: 0px;
  color: #dddddd;
}
/* .css-dwuj3p-MuiTableCell-root {
  text-align: center !important ;
} */
.value12 {
  padding-left: 3rem;
}
span.MuiTypography-root.MuiTypography-span.value123.css-37jdci-MuiTypography-root {
  padding-left: 3rem !important;
}

@media screen and (max-width: 700px) {
  .value12 {
    padding-left: 0rem;
  }
  .inner-box {
    width: 98%;
  }
  span.MuiTypography-root.MuiTypography-span.value123.css-37jdci-MuiTypography-root {
    padding-left: 0rem !important;
  }
}

.inner-box {
  width: 85%;
}
.css-mhc70k-MuiGrid-root > .MuiGrid-item {
  padding: 16px;
}

@media screen and (min-width: 900px) {
  .Box1A {
    border-right: 0.1rem solid #cacdd1;
  }
}
@media screen and (min-width: 900px) {
  .Box1A {
    border-right: 0.1rem solid #cacdd1;
  }
}
@media screen and (min-width: 900px) {
  .hr {
    display: none;
  }
}
button.stakenow {
  width: 100%;
  border-radius: 10px;
  background-image: linear-gradient(90deg, #ff7a00, #e8ab76, #07c3f2);
  padding: 12px;
}
.stake-now1 {
  border: none;
  width: 100%;
  padding: 1rem 0rem;
  background-color: #0064f0;
  border-radius: 10px;
  margin-top: 1rem;
  color: white;
}
.dropdown {
  margin-top: -12px;
  padding: 8px 0px;
}
button.btn.btn-success {
  background-color: white !important;
  color: black !important;
  border: 1px solid #fff;
  width: 100%;
  margin: 0 !important;
}
button.btn.btn-success:hover {
  background-color: white !important;
}
.btn-success.dropdown-toggle {
  background-color: white !important;
}
.dropdown-item:hover {
  background-color: white !important;
}

@media screen and (max-width: 576px) {
  #stake45 {
    font-size: 22px !important;
    margin-left: 0.5rem;
    font-weight: 700;
  }
}
.btn {
  margin: 0.375rem;
  color: inherit;
  text-transform: uppercase;
  word-wrap: break-word;
  white-space: normal;
  cursor: pointer;
  border: 0;
  border-radius: 0.125rem;
  box-shadow: 0 2px 5px 0rgba (0, 0, 0, 0.16), 0 2px 10px 0rgba (0, 0, 0, 0.12);
  padding: 0.84rem 0px !important;
  font-size: 0.81rem;
}

.dropdown-toggle::after {
  display: inline-block;
  /* margin-left: 5.255em !important; */
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.btn-success.dropdown-toggle:hover,
.btn-success.dropdown-toggle:focus {
  background-color: white !important;
}
.btn-success:not([disabled]):not(.disabled):active,
.btn-success:not([disabled]):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  background-color: white !important;
}
.show > .btn-success.dropdown-toggle {
  background-color: white !important;
}
/* #dropdown-basic{
  margin-bottom: 1rem !important;
  margin-left: 2.2rem;
  float: right !important;

} */

#dropdown-basic:hover {
  background-color: white !important;
}
#dropdown-basic:focus {
  background-color: white !important;
}
.dropdown {
  /* border-bottom: 1px solid black; */
}
.dop12 {
  float: left !important;
  margin-left: 2rem;
  font-size: 1.5rem;
}
.dropdown-toggle::after {
  font-size: 1.5rem;
  margin-top: 0.7rem;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  margin-top: 8px !important;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  /* margin-right: 47px; */
  float: right;
}
#drop546 ::after {
  margin-right: 47px !important;
  margin-top: 14px !important;
}
@media screen and (max-width: 750px) {
  button.connectButton {
    margin: 5px 0px;
  }
  button#dropdownMenuButton {
    width: 214px;
  }
}
@media screen and (min-width: 750px) {
  .staking-detail {
    width: 93% !important;
  }
}

@media screen and (max-width: 912px) {
  button.connectButton2 {
    width: 176px;
    padding: 10px 10px;
    border: none;
    font-weight: 500;
    background-color: #0064f0 !important;
    color: #fff;
    border-radius: 10px;
  }
}
.gcs-audit {
  color: white !important;
}
.buygcs-1 {
  width: 22rem !important;
  font-weight: 800;
  height: 3rem;
}

@media screen and (max-width: 350px) {
  .footer-area {
    display: block;
    padding-top: 100px;
    padding-bottom: 10px;
  }
  h6.MuiTypography-root.MuiTypography-h6.gcsaudit.css-2ulfj5-MuiTypography-root {
    display: block;
    margin: 0px auto 10px !important;
  }
}
/* 
@media screen and (max-width: 1024px) {
  a.btn.btn-primary.icon-fa {
    box-shadow: none;
    font-size: 20px;
    background-color: transparent !important;
    border-color: white;
    padding: 5px 15px !important;
    color: rgb(0, 100, 240) !important;
  }
} */
.admin-tab-nav.MuiBox-root.css-0 {
  min-width: 250px;
  overflow: auto;
}

@keyframes blinking {
  0% {
    background-color: #b63c2e;
    border: 1px solid rgb(122, 68, 68);
  }
  100% {
    background-color: #ff8c8c;
    border: 1px solid rgb(122, 68, 68);
  }
}
#blink {
  animation: blinking 1s infinite;
}

@media screen and (max-width: 899px) {
  .footersocial {
    margin-left: 1.6rem;
  }
  .social-links {
    margin-left: 14px;
    margin-top: 11px;
  }
}
