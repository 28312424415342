.totalsupply.MuiBox-root.css-0 {
  border-radius: 7px;
  margin-left: 30px;
  color: #000;
  display: block;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  margin: 10px auto 10px;
}

h3.totalsupplystyle {
  font-size: 19px;
  text-align: center;
  padding: 20px 0px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px !important;
}
h6.marketmmk {
  background: none !important;
  border: none !important;
  color: #000 !important;
  width: 100% !important;
  font-size: 22px;
  font-weight: 500;
  text-align: center !important;
}
.MuiTabs-flexContainer.css-k008qs {
  display: flex !important;
  justify-content: center !important;
  margin-top: 30px !important;
}
.card.mx-3 {
  margin-top: 5rem;
}
.card.gcsswap.mx-3 {
  margin-top: 5rem;
}
h3.footer-name {
  font-weight: 500;
}
.css-1cf86vr {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: none !important ;
  border-radius: 4px;
  box-shadow: 0px 0px 0px !important;
  width: 100%;
  overflow: hidden;
}
.css-1evjoiv {
  width: 100%;
  overflow-x: auto;
  max-height: 440px;
  border: 1px solid !important;
  margin-top: 25px !important;
}
h6.notmasterminter {
  background: none !important;
  border: none !important;
  color: red !important;
  text-align: center !important;
  display: block !important;
  font-weight: 500 !important;
  margin: 40px auto 50px !important;
  width: 100% !important;
}
.mmkprice.MuiBox-root.css-0 {
  border: 1px solid;
  width: 90px;
  padding: 10px;
  text-align: center;
  display: block;
  margin: auto;
  font-size: 19px;
}
button.className-mint {
  width: 70px;
  color: #fff;
  background: green !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 5px;
  padding: 7px 0px;
}
button.className-burn {
  width: 70px;
  border-radius: 5px;
  color: #fff;
  background: red !important;
  border: none !important;
  box-shadow: none !important;
  padding: 7px 0px;
}
span.suggestionMint.fw-bold.p-2 {
  text-align: center;
  display: block;
  margin: auto;
}

button.className-mint2 {
  display: block;
  margin-left: 4rem;
  background: green;
  color: #fff;
  border-radius: 5px;
  width: 120px;
  margin-top: 10px;
  margin-bottom: 10px;
}
button.className-mint3 {
  display: block;
  margin-left: 2.3rem;
  background: green;
  width: 120px;
  padding: 7px 0px;
  border-radius: 5px;
  border: none !important;
  box-shadow: none !important;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
}
button.className-burn2 {
  display: block;
  margin-left: 2.3rem;
  padding: 7px 0px;
  width: 120px;
  border-radius: 5px;
  background: red;
  border: none !important;
  box-shadow: none !important;
  color: #fff;
  margin-top: 10px;
}
.mint-burn-button.MuiBox-root.css-0 {
  display: flex;
  justify-content: center;
}
h5.effectivemmkrate-heading {
  text-align: center !important;
  margin: 50px 0px 0px !important;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
}
.css-1yhpg23-MuiTableCell-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: center;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
}
.css-1fw5qro-MuiPaper-root {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: none !important;
  transition: none !important;
  border-radius: 4px;
  box-shadow: none !important;
  width: 100%;
  overflow: hidden;
}
.MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
  display: flex !important;
  justify-content: center !important;
  margin-top: 30px !important;
}
.calculate-prize {
  display: block;
  margin: auto;
}
button.buttoncalculate {
  display: block;
  margin: 30px auto 0px;
  width: 100px;
  padding: 7px 0px;
  background: rgb(0, 100, 240) !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 5px;
  color: #fff;
}
button.buttoncalculate2 {
  display: block;
  margin: 30px auto 0px;
  width: 200px;
  border-radius: 5px;
  color: #fff;
  padding: 7px 0px;
  background: rgb(0, 100, 240) !important;
  border: none !important;
  box-shadow: none !important;
}
.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.d-flex.justify-center.css-mhc70k-MuiGrid-root {
  justify-content: center;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #000 !important;
}

.gcexfooter {
  margin-top: 20px;
}
h3.MuiTypography-root.MuiTypography-h3.footer-name.css-gepadz-MuiTypography-root {
  font-size: 25px !important;
  font-style: normal !important;
  margin-bottom: 15px !important;
  font-weight: 400 !important;
}
li {
  list-style: none !important;
}
.gcs-audit {
  color: #000 !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
input {
  margin-top: 10px !important;
}
svg {
  color: #282424;
}
input.rescuerinput {
  color: green;
}
p.timeremaining {
  margin-bottom: -20px;
  margin-top: 33px;
  margin-left: 15px;
}
.card.my-5.mx-3.margintop {
  margin-top: 5.2rem !important;
}
input.rescuerinput2 {
  color: red;
  display: block;
  margin: auto;
}
span.fw-bold.suggestionBurn.p-2 {
  text-align: center;
  display: block;
  margin: auto;
}
span.fw-bold.suggestionBurn.p-2 {
  text-align: center;
  display: block;
  margin: auto;
}
p.text-center {
  margin-bottom: 0rem !important;
  padding-bottom: 10px;
}
.progress-bar.MuiBox-root.css-0 {
  margin-top: 27px;
  margin-bottom: -30px;
  width: 95%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
button.addminterbutton {
  width: 120px;
  display: block;
  margin-left: 30px;
  background: rgb(0, 100, 240) !important;
  margin-top: 10px;
  border: none !important;
  box-shadow: none !important;
  border-radius: 5px;
  color: #fff;
  padding: 7px 0px;
}
button.addminterbutton2 {
  width: 150px;
  display: block;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(0, 100, 240) !important;
  border: none !important;
  box-shadow: none !important;
  margin-top: 10px;
  color: #fff;
  padding: 7px 0px;
}
button.addminterbutton3 {
  width: 150px;
  display: block;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(0, 100, 240) !important;
  border: none !important;
  box-shadow: none !important;
  margin-top: 10px;
  color: #fff;
  padding: 10px 0px;
}
button.addminterbuttonconnect {
  width: 300px;
  display: block;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(0, 100, 240) !important;
  border: none !important;
  box-shadow: none !important;
  margin-top: 10px;
  color: #fff;
  padding: 7px 0px;
}
button.addminterbuttonconnect3 {
  width: 300px;
  display: block;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(0, 100, 240) !important;
  border: none !important;
  box-shadow: none !important;
  margin-top: 9px;
  color: #fff;
  padding: 10px 0px;
}
@media screen and (max-width: 1024px) and (min-width: 900px) {
  .totalsupply.MuiBox-root.css-0 {
    max-width: 310px;
    display: block;
    margin: 10px auto;
  }
}
@media screen and (max-width: 768px) {
  .MuiTabs-flexContainer.css-k008qs {
    width: 100% !important;
    display: flex !important;
    overflow: scroll !important;
    justify-content: flex-start !important;
    position: relative !important;
    flex-wrap: nowrap !important;
  }
  /* #flextabs {
    width: 100% !important;
    display: flex !important;
    overflow: scroll !important;
    justify-content: flex-start !important;
    position: relative !important;
    flex-wrap: nowrap !important;
  } */
}
